@tailwind base;
@tailwind components;
@tailwind utilities;

/**
 * CSS variables
 **/
:root {
  --top-nav-height: 64px;
  --upgrade-banner-height: 56px;

  /* Primary Colors (Neutral) */
  --primary-color-200: #000f0a; /* Darkest */
  --primary-color-150: #232b29;
  --primary-color-125: #394642;
  --primary-color-110: #68716e;
  --primary-color-100: #dbd8d1;
  --primary-color-75: #eae8e4;
  --primary-color-50: #f4f3f1;
  --primary-color-30: #f8f8f7;
  --primary-color-25: #faf9f8;
  --primary-color-20: #fdfdfc;
  --primary-color-0: #ffffff; /* Lightest */

  /* Secondary Colors (Green) */
  --secondary-color-200: #001e07;
  --secondary-color-150: #002d0a; /* Darkest */
  --secondary-color-125: #004704;
  --secondary-color-100: #00b20a;
  --secondary-color-75: #44c74c;
  --secondary-color-50: #8add8e;
  --secondary-color-25: #e2fbe5; /* Lightest */
  --secondary-color-20: #f0f9f1;
}

::selection {
  background-color: var(--secondary-color-25);
}

.react-colorful {
  background-color: #faf9f8;
  border-color: #eae8e4;
  border-width: 1px;
  border-radius: 12px;
  padding: 16px;
}

.react-colorful__saturation {
  border-radius: 4px;
  margin-bottom: 16px;
}

.react-colorful__hue {
  border-radius: 4px;
}

/**
 * FK Grotesk Neue
 **/

@font-face {
  font-family: FKGroteskNeue;
  src: url('./assets/fonts/FKGroteskNeue-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FKGroteskNeue;
  src: url('./assets/fonts/FKGroteskNeue-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: FKGroteskNeue;
  src: url('./assets/fonts/FKGroteskNeue-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FKGroteskNeue;
  src: url('./assets/fonts/FKGroteskNeue-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: FKGroteskNeue;
  src: url('./assets/fonts/FKGroteskNeue-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FKGroteskNeue;
  src: url('./assets/fonts/FKGroteskNeue-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: FKGroteskNeue;
  src: url('./assets/fonts/FKGroteskNeue-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FKGroteskNeue;
  src: url('./assets/fonts/FKGroteskNeue-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: FKGroteskNeue;
  src: url('./assets/fonts/FKGroteskNeue-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FKGroteskNeue;
  src: url('./assets/fonts/FKGroteskNeue-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: FKGroteskNeue;
  src: url('./assets/fonts/FKGroteskNeue-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FKGroteskNeue;
  src: url('./assets/fonts/FKGroteskNeue-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

/**
 * FK Roman Standard
 **/

@font-face {
  font-family: FKRomanStandard;
  src: url('./assets/fonts/FKRomanStandard-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FKRomanStandard;
  src: url('./assets/fonts/FKRomanStandard-ThinOblique.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: FKRomanStandard;
  src: url('./assets/fonts/FKRomanStandard-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FKRomanStandard;
  src: url('./assets/fonts/FKRomanStandard-LightOblique.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: FKRomanStandard;
  src: url('./assets/fonts/FKRomanStandard-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FKRomanStandard;
  src: url('./assets/fonts/FKRomanStandard-Oblique.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: FKRomanStandard;
  src: url('./assets/fonts/FKRomanStandard-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FKRomanStandard;
  src: url('./assets/fonts/FKRomanStandard-MediumOblique.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: FKRomanStandard;
  src: url('./assets/fonts/FKRomanStandard-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FKRomanStandard;
  src: url('./assets/fonts/FKRomanStandard-BoldOblique.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: FKRomanStandard;
  src: url('./assets/fonts/FKRomanStandard-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FKRomanStandard;
  src: url('./assets/fonts/FKRomanStandard-BlackOblique.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/**
 * Freehand
 **/

@import url('https://fonts.googleapis.com/css2?family=Freehand&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply font-sans text-body;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-serif;
}

th {
  @apply px-4 py-3;
}

td {
  @apply px-4 py-3;
}

input {
  @apply selection:bg-secondary-25;
}

textarea {
  @apply selection:bg-secondary-25;
}

/* Styling for article content HTML */
.article-content p {
  margin-bottom: 15px;
}

.article-content h1,
.article-content h2,
.article-content h3 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.article-content ul {
  list-style: disc;
  padding-left: 15px;
}

.article-content li {
  margin-bottom: 5px;
}

.article-content hr {
  margin-top: 20px;
  margin-bottom: 20px;
}

.article-content a.button {
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px 0;
  display: inline-block;
}

.react-datepicker__input-container input {
  @apply form-input block w-full rounded border-2 border-transparent bg-neutral-50 py-3 px-4 text-body text-green-150 transition-colors placeholder:text-neutral-125/75 read-only:text-neutral-125/75 focus:border-green-50 focus:outline-none focus:ring-0;
}

.react-datepicker-popper {
  @apply z-50 w-72 rounded-md bg-white px-3 py-2 text-caption shadow-400;
}

.react-datepicker-left {
  @apply absolute left-0 right-auto top-20 transform-none !important;
}

.react-datepicker-right {
  @apply absolute right-0 left-auto top-20 transform-none !important;
}

.react-datepicker__portal {
  @apply absolute top-12 right-0 z-10 w-72 transform-none rounded border-2 border-neutral-25 bg-white px-3 py-2 text-caption shadow;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 mt-2 text-body font-medium text-green-150;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply mt-2 flex justify-around text-center text-small-caption font-medium text-neutral-100 focus:ring-green-50;
}

.react-datepicker__day-name {
  @apply flex h-8 w-8 items-center justify-center rounded-full py-1 focus:ring-green-50;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 flex h-8 w-8 items-center justify-center rounded transition hover:bg-neutral-25 focus:ring-green-50;
}

.react-datepicker__navigation--next {
  @apply right-4 flex h-8 w-8 items-center justify-center rounded transition hover:bg-neutral-25 focus:ring-green-50;
}

.react-datepicker__day {
  @apply mb-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded py-1 text-caption leading-loose text-neutral-125 transition hover:bg-neutral-50 focus:ring-green-50;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-neutral-50;
}

.react-datepicker__day--in-range {
  @apply bg-neutral-25;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-25;
}

.react-datepicker__day--selecting-range-start {
  @apply border-2 border-green-100 bg-white;
}

.react-datepicker__day--selecting-range-end {
  @apply border-2 border-green-100 bg-white;
}

.react-datepicker__day--selected {
  @apply bg-green-125 text-white hover:bg-green-150;
}
