.fc.fc-theme-standard {
  --fc-now-indicator-color: #004704;
  --fc-event-bg-color: #6e77747a;
  --fc-event-text-color: #ffffff;
  --fc-highlight-color: rgba(0, 71, 4, 0.1);
  --fc-event-border-color: transparent;
}

.fc .fc-timegrid-now-indicator-line {
  border-width: 1px !important;
}

.fc .fc-timegrid-axis-cushion {
  /* Time column styling */
  padding-right: 8px;
}

.fc .fc-timegrid-slot-label {
  /* Time slots styling */
  border-color: #e5e7eb !important; /* neutral-75 */
}

.fc .fc-timegrid-divider {
  /* Remove the default divider */
  display: none !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #e5e7eb; /* neutral-75 */
}

.fc-theme-standard .fc-scrollgrid {
  border-top: none;
}

/* Make all-day section height fit content with minimum height */
.fc .fc-daygrid-body {
  min-height: 21px !important;
}

.fc .fc-daygrid-body table {
  height: auto !important;
}

/* Set minimum height for day cells */
.fc .fc-daygrid-day-frame {
  min-height: 21px !important;
  height: auto !important;
}

.fc .fc-daygrid-day-events {
  min-height: 21px !important;
  margin-bottom: 0 !important;
}

/* Hide the empty bottom padding/margin in day cells */
.fc .fc-daygrid-day-bottom {
  padding: 0 !important;
  margin: 0 !important;
}

/* Change today's background to neutral */
.fc .fc-day-today {
  background-color: #f5f5f5 !important;
}

/* Add fade-in animation for calendar events */
.fc-event {
  animation: fadeIn 0.12s ease-in;
  opacity: 1;
  border: none !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
